
import { Box, Card, Divider, Typography } from '@mui/joy';
import { Grid, Icon } from '@mui/material';
import { createTransaction } from './payment.service';
import Sheet from '@mui/joy/Sheet';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

function App() {

  const { sessionId } = useParams<any>();

  const { data, isLoading, error} = useQuery('session-'+sessionId, async () => {
      const response = await fetch("https://device-endpoint.reuseacup.com/temp-payment/session/"+sessionId)
      return response.json();
    }
  );

  const handleCardPayout = () => {
    createTransaction(sessionId!)
  }

  if(isLoading){
    return (
      <Box>
        <Typography fontWeight="bold" level="title-md">Loading..</Typography>
      </Box>
    )
  }

  if(error){
    return (
      <Box>
        <Typography fontWeight="bold" level="title-md">Session not found or expired.</Typography>
      </Box>
    )
  }

  return (
    <Box>

      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1}}>
        <img src="/images/fcz.png" width="40" alt="Test"/>
        <img src="/images/logo.png" width="38" alt="Test"/>
      </Box>

      <Divider />

      <Box padding={2}>
        <Card variant='outlined' sx={{padding: 2}}>
          <Typography level="title-lg" fontWeight="bold">{data.amountToDisplay} Fr.- Pfand</Typography>
          <Typography level="body-md" >{data.objectCount} Becher</Typography>
        </Card>

        <Box sx={{paddingBottom: 2}}>

          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", padding: 2}}>
            <Icon sx={{color: "#dc2626", fontSize: 40}}>favorites</Icon>
            <Typography level="h3" fontWeight="bold">Spenden</Typography>
            <Typography level="body-xs">Tun sie etwas gutes, jeder Franken zählt!</Typography>
          </Box>

          <Grid container spacing={2} >


            <Grid item xs={6}>
              <Sheet variant="outlined" sx={{padding: 2, borderRadius: "12px", display: "flex", alignItems: "center", height: 50, cursor: "pointer"}}>
                <img src="/images/fcz.png" width="40" alt="Test"/>
                <Typography level="title-sm" fontWeight="bold" sx={{marginLeft: 2}}>FCZ</Typography>
              </Sheet >
            </Grid>

            <Grid item xs={6}>
              <Sheet variant="outlined" sx={{padding: 2, borderRadius: "12px", display: "flex", alignItems: "center", height: 50, cursor: "pointer"}}>
              <img src="/images/srk.png" width="30" alt="Test"/>
                <Typography level="title-sm" fontWeight="bold" sx={{marginLeft: 2}}>SRK</Typography>
              </Sheet>
            </Grid>
            
          </Grid>

        </Box>

        <Divider />

        <Box>

          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", padding: 2}}>
            <Icon sx={{color: "#16a34a", fontSize: 40}}>payments</Icon>
            <Typography level="h3" fontWeight="bold">Auszahlen</Typography>
            <Typography level="body-xs">Das Geld wird auf das gewählte Zahlungsmittel gutgeschrieben.</Typography>
          </Box>

          <Grid container spacing={2} >

      
            <Grid item xs={6}>
              <Sheet variant="outlined" sx={{padding: 2, borderRadius: "12px", display: "flex", alignItems: "center", height: 50, cursor: "pointer"}} onClick={handleCardPayout}>
                <Typography level="title-sm" fontWeight="bold" sx={{marginLeft: 2}}>Card</Typography>
              </Sheet >
            </Grid>

            <Grid item xs={6}>
              <Sheet variant="outlined" sx={{padding: 2, borderRadius: "12px", display: "flex", alignItems: "center", height: 50, cursor: "pointer"}}>

                <Typography level="title-sm" fontWeight="bold" sx={{marginLeft: 2}}>Twint</Typography>
              </Sheet>
            </Grid>
            
          </Grid>

        </Box>

      </Box>
    </Box>
  );
}

export default App;
