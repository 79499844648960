

//IMPORTANT: This is not secure and only for demonstration

import axios from "axios";

export function createTransaction(sessionId: string){
    axios.post("https://cup-project-payout.davide-17d.workers.dev", {
        sessionId
    }, {

    }).then((response)=>{
        window.location.href = response.data
    })
}